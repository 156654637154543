import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { Store as ngrxStore } from '@ngrx/store';
import { APIService } from 'app/services/api.service';
import { Organization } from 'app/models/organization.model';
import {
  ClearOrganization,
  CreateOrganization,
  GetExternalSystemsInfo,
  InitOrganizations,
  LoadRevisionForPipeGroups,
  MenuStatusChanged,
  PipeRevisionChanged,
  SetMasterOrganization,
  SetOrganization,
  SetPipes,
  SwitchDisplayDeletedPipesAndConfigurations,
  UpdateOrganization
} from '../actions/organizations.actions';
import { patch } from '@ngxs/store/operators';
import { MappedPipeConfiguration, PipeConfiguration } from 'app/models/pipe.model';
import { map, Observable, of, tap } from 'rxjs';
import { IExternalSystem } from 'app/models/externalSystem.model';
import { SortArrayPipe } from 'app/shared/pipes/sort-array.pipe';
import { SnackbarService } from 'app/services/snackbar.service';
import { catchError } from "rxjs/operators";
import { PipeGroup } from "app/models/pipeGroup.model";
import { ExternalSystemHelper } from "app/helpers/externalSystemHelper";
import { PipeConfigurationDetailed, PipeRevisionStatus } from "app/models/organizations.models";
import { MenuStorage } from "app/models/menuStorage.model";

export class OrganizationsStateModel {
  // Parent organizations
  organizations: Organization[];
  selectedOrganizationId: string | null;
  showDeletedPipesAndConfigurations: boolean;
  pipes: PipeConfiguration[];
  pipesRevisionStatuses: { [key: string]: PipeRevisionStatus };
  pipesMenuStatuses: { [key: string]: MenuStorage };
  externalSystems: IExternalSystem[];
}

@State<OrganizationsStateModel>({
  name: 'organizations',
  defaults: {
    organizations: [],
    selectedOrganizationId: localStorage.getItem('selectedOrganizationId'),
    showDeletedPipesAndConfigurations: false,
    pipes: [],
    pipesRevisionStatuses: {},
    pipesMenuStatuses: {},
    externalSystems: [],
  },
})

@Injectable()
export class OrganizationsState implements NgxsOnInit {

  constructor(
    private store: Store,
    private ngrxStore: ngrxStore,
    private apiProvider: APIService,
    private sortArrayPipe: SortArrayPipe,
    private snackbar: SnackbarService
  ) {
  }

  @Selector()
  static getOrganizations(state: OrganizationsStateModel): Organization[] {
    return state.organizations.filter(o => o.parentId === null);
  }

  @Selector()
  static getBranches(state: OrganizationsStateModel): Organization[] {
    return state.organizations;
  }

  @Selector()
  static getActiveBranches(state: OrganizationsStateModel): Organization[] {
    return this.getBranches(state)
      .filter(organization => organization.isActive);
  }

  @Selector()
  static getPipes(state: OrganizationsStateModel): PipeConfiguration[] {
    return state.pipes;
  }

  @Selector()
  static getPipeDetails(state: OrganizationsStateModel): PipeConfigurationDetailed[] {
    const status = state.pipesRevisionStatuses;
    return state.pipes.map(value => {
      return {
        ...value,
        revisionStatus: status[value.id] ? status[value.id] : {
          revision: "",
          updatedAt: "",
          pipeId: value.id,
          isLoading: false
        },
        menuStatus: state.pipesMenuStatuses[value.id]
          ? state.pipesMenuStatuses[value.id]
          : {
            id: '',
            revision:'',
            uploadedAt: null,
            published: null,
            uploadMessage: null,
            uploadResult: null,
            derivativeId: '',
            sourceId:'',
            targetId: '',
            guidCombinationsId: '',
            pipeId: '',
          },
      }
    });
  }

  @Selector()
  static getPipeGroups(state: OrganizationsStateModel): PipeGroup[] {

    const pipes = this.getPipeDetails(state);
    const pipesRevisionStatuses = state.pipesRevisionStatuses;
    const externalSystems = state.externalSystems;

    let mappedPipes: MappedPipeConfiguration[] = [];

    const gecoPosSalesPipes = pipes.filter(p =>
      ExternalSystemHelper.getExternalSystemTypeByKey(externalSystems, p.sourceConfiguration.externalSystemType) === 'GECOPOSSALES');

    const gecoPosPipes = pipes.filter(p =>
      ExternalSystemHelper.getExternalSystemTypeByKey(externalSystems, p.targetConfiguration.externalSystemType) === 'GECOPOS');

    // для конфигов pos, связанных с gecoPosSales через pipe находим конфиги агрегаторов, связанных с gecoPos черех pipe
    for (let salesPipe of gecoPosSalesPipes) {
      for (let posPipe of gecoPosPipes) {
        mappedPipes = [...mappedPipes, {
          salesPipe: salesPipe,
          posPipe: posPipe,
        } as MappedPipeConfiguration];
      }
    }


    let newBranchPipesGroup: PipeGroup[] = [];
    pipes.map(pipe => {
      const targetExternalSystem = externalSystems.find(x => x.key == pipe.targetConfiguration.externalSystemType);
      const sourceExternalSystem = externalSystems.find(x => x.key == pipe.sourceConfiguration.externalSystemType);

      if (
        pipe.isDeleted
        || !pipe.isActive
        || pipe.targetConfiguration.isDeleted
        || pipe.sourceConfiguration.isDeleted
        || targetExternalSystem.externalSystemType === 'GECOPOS'
        || sourceExternalSystem.externalSystemType === 'GECOPOSSALES'
        || !targetExternalSystem.params.isSupportMenuExport
      ) {
        return;
      }

      let pipeGroup = newBranchPipesGroup
        .find(pg =>
          pg.targetExternalSystemType === targetExternalSystem.externalSystemType &&
          pg.targetConfiguration.id === pipe.targetConfiguration.id);
      if (pipeGroup) {
        pipeGroup.pipes.push(pipe);
      } else {
        const status = pipesRevisionStatuses[pipe.id]

        newBranchPipesGroup.push({
          id: pipe.targetConfiguration.id,
          targetExternalSystemType: targetExternalSystem.externalSystemType,
          targetConfiguration: pipe.targetConfiguration,
          pipes: [pipe],
          revision: status?.revision ? status.revision : "",
          updatedAt: status?.updatedAt ? status.updatedAt : "",
          isLoadedRevisionInfo: !!status,
          mappedPipes: [],
        } as PipeGroup);
      }

      newBranchPipesGroup = newBranchPipesGroup.sort((prev, next) => {
        return prev.targetConfiguration.externalSystemType - next.targetConfiguration.externalSystemType;
      });
    });

    mappedPipes.map(mappedPipe => {
      const sourceExternalSystem = externalSystems.find(x => x.key == mappedPipe.posPipe.sourceConfiguration.externalSystemType);
      const targetExternalSystem = externalSystems.find(x => x.key == mappedPipe.salesPipe.targetConfiguration.externalSystemType);

      let pipeGroup = newBranchPipesGroup
        .find(pg =>
          pg.targetExternalSystemType === targetExternalSystem.externalSystemType &&
          pg.targetConfiguration.id === mappedPipe.salesPipe.targetConfiguration.id);

      if (pipeGroup) {
        pipeGroup.mappedPipes.push(mappedPipe);
      } else {
        newBranchPipesGroup.push({
          id: mappedPipe.salesPipe.targetConfiguration.id,
          targetExternalSystemType: targetExternalSystem.externalSystemType,
          targetConfiguration: mappedPipe.salesPipe.targetConfiguration,
          pipes: [],
          revision: mappedPipe.salesPipe.menuStatus.revision,
          // TODO: непонятен смысл этого поля, если оно аналогично ревизии в timeStamp
          updatedAt: mappedPipe.salesPipe.menuStatus.revision,
          isLoadedRevisionInfo: false,
          mappedPipes: [mappedPipe],
        } as PipeGroup);
      }
    });

    return newBranchPipesGroup;
  }

  @Selector()
  static getBrancheIds(state: OrganizationsStateModel): string[] {
    const organizationIds = [];
    const branches = state.organizations;
    for (const branch of branches) {
      organizationIds.push(branch.id);
    }
    return organizationIds;
  }

  @Selector()
  static getSelectedOrganizationId(state: OrganizationsStateModel): string {
    return state.selectedOrganizationId;
  }

  @Selector()
  static getSelectedOrganizationTree(state: OrganizationsStateModel): Organization[] {
    if (state.selectedOrganizationId && state.selectedOrganizationId.length > 0) {
      return state.organizations.filter(o => o.parentId === state.selectedOrganizationId || o.id === state.selectedOrganizationId);
    } else {
      return [];
    }

  }

  @Selector()
  static getSelectedOrganizationBranches(state: OrganizationsStateModel): Organization[] {
    if (!state.selectedOrganizationId || !state.selectedOrganizationId.length) {
      return [];
    }

    let mainOrganization = this.getSelectedOrganization(state);

    if (mainOrganization.parentId){
      mainOrganization = state.organizations.find(o => o.id === mainOrganization.parentId);
    }

    return state.organizations
      .filter(o => o.parentId === mainOrganization.id || o.id === mainOrganization.id);
  }

  @Selector()
  static getSelectedOrganizationTreeIds(state: OrganizationsStateModel): string[] {
    return this.getSelectedOrganizationTree(state).map(value => value.id);
  }

  @Selector()
  static getSelectedOrganization(state: OrganizationsStateModel): Organization {
    return this.getActiveBranches(state).find(organization => organization.id === state.selectedOrganizationId);
  }

  @Selector()
  static getDataForOrderConverter(state: OrganizationsStateModel): {
    organizations: Organization[],
    externalSystems: IExternalSystem[]
  } {
    const organization = state.organizations.find(organization => organization.id === state.selectedOrganizationId);
    let organizations = [];
    if (organization) {
      organizations = [organization, ...organization.children];
    }

    return {
      organizations: organizations,
      externalSystems: state.externalSystems
    };
  }

  @Selector()
  static showDeletedPipesAndConfigurations(state: OrganizationsStateModel): boolean {
    return state.showDeletedPipesAndConfigurations;
  }

  @Selector()
  static pipes(state: OrganizationsStateModel): PipeConfiguration[] {
    return state.pipes;
  }

  @Selector()
  static getExternalSystems(state: OrganizationsStateModel): IExternalSystem[] {
    return state.externalSystems;
  }

  @Selector()
  static getExternalSystemByKey(state: OrganizationsStateModel): (key: number) => IExternalSystem {
    return (key: number) => {
      return state.externalSystems.find(x => x.key === key);
    };
  }

  ngxsOnInit(ctx: StateContext<OrganizationsStateModel>): void {
  }


  @Action(LoadRevisionForPipeGroups)
  getRevisionForPipeGroups(context: StateContext<OrganizationsStateModel>, action: LoadRevisionForPipeGroups): void {
    const state = context.getState();

    const pipesStatuses = {};

    const processed: string[] = [];
    action.pipes.every(pipe => {
      // нам нужна ревизия только для iiko в данный момент
      const id = pipe.targetConfiguration.id;
      const sourceId = pipe.sourceConfiguration.id;
      if (!pipe.isActive || pipe.isDeleted) {
        return true;
      }

      this.apiProvider.getMenuStorageList(pipe.id)
        .then(response => {
          const menuHistory = response.result;
          if (menuHistory) {
            const onlyPublishedMenus = menuHistory.filter((m: MenuStorage) => m.published !== null);
            onlyPublishedMenus.sort((a: MenuStorage, b: MenuStorage) => {
              return a.published < b.published ? 1 : -1;
            });
            if (onlyPublishedMenus.length > 0) {
              context.dispatch(new MenuStatusChanged(pipe.id, onlyPublishedMenus[0]))
            }
          } else {
            return null;
          }
        });

      if (processed.indexOf(id) !== -1) {
        return true;
      } else {
        processed.push(id)
      }

      if (!pipesStatuses[id]) {
        pipesStatuses[id] = true;

        context.dispatch(new PipeRevisionChanged({
          pipeId: pipe.id,
          isLoading: true,
          revision: "",
          updatedAt: ""
        }))

        this.apiProvider.getRevision(id).then(response => {
          context.dispatch(new PipeRevisionChanged({
            pipeId: pipe.id,
            isLoading: false,
            revision: response.revision,
            updatedAt: response.updatedAt
          }))
        }).catch(reason => {
          context.dispatch(new PipeRevisionChanged({
            pipeId: pipe.id,
            isLoading: false,
            revision: "",
            updatedAt: ""
          }))
        });
      }

      return true;
    });
  }

  @Action(PipeRevisionChanged)
  pipeRevisionChanged(context: StateContext<OrganizationsStateModel>, action: PipeRevisionChanged): void {
    context.patchState({
      pipesRevisionStatuses: {
        ...context.getState().pipesRevisionStatuses,
        [action.status.pipeId]: action.status
      }
    })
  }

  @Action(MenuStatusChanged)
  menuStatusChanged(context: StateContext<OrganizationsStateModel>, action: MenuStatusChanged): void {
    context.patchState({
      pipesMenuStatuses: {
        ...context.getState().pipesMenuStatuses,
        [action.pipeId]: action.status
      }
    })
  }


  @Action(InitOrganizations)
  public initOrganizations(ctx: StateContext<OrganizationsStateModel>): Observable<Organization[]> {
    return this.apiProvider.getOrganizationsList()
      .pipe(
        tap(res => {
          if (res.result) {
            const flatOrganizations = res.result;
            const parentOrganisations = flatOrganizations.filter(o => !o.parentId);
            const data = [];
            if (parentOrganisations && parentOrganisations.length) {
              data.push(...parentOrganisations.reduce((buffer, parent) => {
                const children = flatOrganizations.filter(o => o.parentId === parent.id);
                return [...buffer, ...[parent, ...children]];
              }, []));
            } else {
              data.push(...flatOrganizations);
            }
            const childOrganizations = data.map(dataItem => {
              const organizations = flatOrganizations.filter(flatOrganization => flatOrganization.parentId === dataItem.id);
              return {
                ...dataItem,
                children: organizations
              };
            });
            ctx.patchState({
              organizations: childOrganizations
            });
          } else if (res.error) {
            this.snackbar.error(res.error.code + ': ' + res.error.message);
          }
        }),
        map(res => {
          if (res.result) {
            return res.result;
          } else {
            return [];
          }
        }),
        catchError(err => {
          return []
        })
      );
  }

  @Action(CreateOrganization)
  createOrganization({getState, patchState}: StateContext<OrganizationsStateModel>, {organization}): void {
    const state = getState();
    let stateOrganizations = null;
    if (organization.parentId) {
      stateOrganizations = state.organizations.map((orgElem: Organization) => {
        if (orgElem.id === organization.parentId) {
          if (orgElem.children) {
            const newOrgChildren = this.sortArrayPipe.transform([...orgElem.children, organization]);
            return {
              ...orgElem,
              children: newOrgChildren
            };
          } else {
            return {
              ...orgElem,
              children: [organization]
            };
          }
        } else {
          return orgElem;
        }
      });
    }
    const newOrganizations = stateOrganizations
      ? this.sortArrayPipe.transform([organization, ...stateOrganizations])
      : this.sortArrayPipe.transform([organization, ...state.organizations]);
    patchState({organizations: newOrganizations});
  }

  @Action(UpdateOrganization)
  updateOrganization({getState, patchState}: StateContext<OrganizationsStateModel>, {organization}): void {
    const state = getState();
    let stateOrganizations;
    if (organization.parentId) {
      stateOrganizations = state.organizations.map(orgElem => {
        if (orgElem.id === organization.parentId) {
          const newOrgElemChildren = orgElem.children.map(child => {
            if (child.id === organization.id) {
              return organization;
            } else {
              return child;
            }
          });
          return {
            ...orgElem,
            children: newOrgElemChildren
          };
        } else {
          return orgElem;
        }
      });
    } else {
      stateOrganizations = state.organizations;
    }
    const newOrganizations = stateOrganizations.map(iOrgElem => {
      if (iOrgElem.id === organization.id) {
        return organization;
      } else {
        return iOrgElem;
      }
    });
    patchState({organizations: newOrganizations});
  }

  @Action(SetOrganization)
  setOrganization({patchState}: StateContext<OrganizationsStateModel>, {organizationId}: SetOrganization): Observable<string> {
    const patchStateRes = patchState({
      selectedOrganizationId: organizationId,
    });
    localStorage.setItem('selectedOrganizationId', organizationId);
    this.ngrxStore.dispatch(SetMasterOrganization(organizationId));
    return of(patchStateRes.selectedOrganizationId);
  }

  @Action(ClearOrganization)
  clearOrganization({patchState}: StateContext<OrganizationsStateModel>): void {
    this.ngrxStore.dispatch(SetMasterOrganization(null));
    localStorage.removeItem('selectedOrganizationId');
    patchState({
      selectedOrganizationId: null,
      organizations: [],
    });
  }

  @Action(SwitchDisplayDeletedPipesAndConfigurations)
  switchDisplayDeletedPipesAndConfigurations({getState, patchState}: StateContext<OrganizationsStateModel>): void {
    const state = getState();
    patchState({
      showDeletedPipesAndConfigurations: !state.showDeletedPipesAndConfigurations
    });
  }

  @Action(SetPipes)
  setPipes(ctx: StateContext<OrganizationsStateModel>, {organizationIds}: SetPipes): void {
    this.apiProvider.getPipes(organizationIds)
      .subscribe(rpcResponse =>
        ctx.setState(
          patch({
            pipes: rpcResponse.result,
          })
        ));
  }

  @Action(GetExternalSystemsInfo)
  getExternalSystemsInfo(ctx: StateContext<OrganizationsStateModel>): Observable<IExternalSystem[]> {
    return this.apiProvider.getExternalSystemsInfo()
      .pipe(
        tap(rpcResponse => {
          ctx.patchState(
            {
              externalSystems: rpcResponse.result.sort(this.sortExternalSystems),
            }
          );
        }),
        map(rpcResponse => {
          return rpcResponse.result;
        })
      );
  }

  sortExternalSystems = (prev: IExternalSystem, next: IExternalSystem): number => {
    return prev.params.name > next.params.name ? 1 : -1;
  };
}
