import { CreateOrganization, Organization, UpdateOrganization } from 'app/models/organization.model';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, of, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  AddUserAccessForOrganizationRequest,
  CreateUserRequest,
  DeleteUserAccessToOrganizationRequest,
  EditUserRequest,
  LoginResponse,
  UserChangePasswordRequest,
  UserLoginData,
  UserWithAccess
} from 'app/models/user.model';
import { RefreshToken } from 'app/models/refreshToken.model';
import { IBaseResponse } from 'app/models/baseResponse.model';
import { PageResult } from 'app/models/pageResult.model';
import { OrderInQueue } from 'app/models/orderInQueue.model';
import { OrderFilter } from 'app/models/orderFilter.model';
import { PipeConfiguration, PipeSwitchRequest } from 'app/models/pipe.model';
import { OrderWithHistory } from 'app/models/orderWithHisotry.model';
import { Configuration } from 'app/models/configuration.model';
import { ConfigurationCreateRequest } from 'app/models/configurationCreateRequest.model';
import { ICalculateDeliveryModel, TravelMethod } from 'app/models/ICalculateDeliveryModel';
import { OrganizationUser } from 'app/models/organizationUser';
import {
  CreateUpdateScheduleRequest,
  EditUpdateScheduleRequest,
  UpdateSchedule
} from 'app/models/updateSchedule.model';
import { ExternalSystemId, IExternalSystem } from 'app/models/externalSystem.model';
import { environment } from 'environments/environment';
import { IRpcResponse } from 'app/models/rpcResponse.model';
import { EventModel } from 'app/models/event.model';
import { BucketName } from 'app/models/bucketName';
import { NotificationConfiguration } from 'app/models/notificationConfiguration.model';
import { ConfigurationNotificationCreateRequest } from 'app/models/configurationNotificationCreateRequest.model';
import { catchError } from 'rxjs/operators';
import { RevisionMenuResponse } from 'app/models/revisionMenuResponse.model';
import { MenuStorage } from 'app/models/menuStorage.model';
import { StopListStorage } from 'app/models/stopListStorage.model';
import { MasterOrderResponse, MasterOrderState, ReflectedOrder } from 'app/models/masterOrder.model';
import { UserAction, UserActionFilter } from 'app/models/user-actions.model';


@Injectable({
  providedIn: 'root',
})
export class APIService {
  // error messages received from the login attempt
  public errors: any = [];
  rpcError$: Subject<any>;
  // http options used for making API calls
  private headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(private http: HttpClient) {
    this.rpcError$ = new Subject();
  }

  public rpcCall<T>(url: string, method: string, params: any = null): Observable<IRpcResponse<T>> {
    const requestParams = {
      id: (new Date).getTime(),
      jsonrpc: '2.0',
      method,
      params
    };
    if (!params) {
      delete requestParams.params;
    }

    return this.http.post<IRpcResponse<T>>(url, JSON.stringify(requestParams, null, 4), {
      headers: this.headers
    });
  }

  // Uses http.post() to get an auth token from djangorestframework-jwt endpoint
  public login(payload: UserLoginData): Observable<IBaseResponse<LoginResponse>> {
    const url = `${environment.baseUrl}/api/auth/login`;
    return this.http.post<IBaseResponse<LoginResponse>>(url, JSON.stringify(payload), {
      headers: this.headers,
    });
  }

  // Uses http.post() to get an auth token from djangorestframework-jwt endpoint
  public loginBackDoor(payload: UserLoginData): Observable<IBaseResponse<LoginResponse>> {
    const url = `${environment.baseUrl}/api/auth/login-back-door`;
    return this.http.post<IBaseResponse<LoginResponse>>(url, JSON.stringify(payload), {
      headers: this.headers,
    });
  }

  refreshToken(refreshTokenRequest: RefreshToken): Observable<IBaseResponse<LoginResponse>> {
    const url = `${environment.baseUrl}/api/auth/refresh/`;
    return this.http.post<IBaseResponse<LoginResponse>>(url, JSON.stringify(refreshTokenRequest), {
      headers: this.headers,
    });
  }

  public decodeToken(token: string): {
    username: string,
    tokenExpires: Date
  } {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(Buffer.from(base64, 'base64').toString().split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    try {
      const tokenDecoded = JSON.parse(jsonPayload);
      const tokenExpires = new Date(tokenDecoded.exp * 1000);
      const username = tokenDecoded.username;
      return {username, tokenExpires};
    } catch (e) {
      console.error('Failed to decode users token: ', jsonPayload);
    }
  }

  // public retrieveUser() {
  //   const url = `${environment.baseUrl}/api/rest-auth/user/`;
  //   return this.http.get<ApplicationUser>(url, { headers: this.headers });

  // public logout() {
  //   const url = `${environment.baseUrl}/api/rest-auth/logout/`;
  //   return this.http.post<UserLoginResponseData>(url, null, {
  //     headers: this.headers,
  //   });
  // }
  public fetchOrders(orderFilter: OrderFilter): Observable<IBaseResponse<PageResult<OrderInQueue>>> {
    const url = `${environment.baseUrl}/api/order/list`;
    return this.http.post<IBaseResponse<PageResult<OrderInQueue>>>(url, JSON.stringify(orderFilter), {
      headers: this.headers,
    });
  }

  public fetchMasterOrders(orderFilter: OrderFilter): Observable<IBaseResponse<PageResult<MasterOrderResponse>>> {
    const url = `${environment.baseUrl}/api/order/master/list`;
    return this.http.post<IBaseResponse<PageResult<MasterOrderResponse>>>(url, JSON.stringify(orderFilter), {
      headers: this.headers,
    });
  }

  public fetchOrderHistory(orderId: string): Observable<IBaseResponse<OrderWithHistory>> {
    const url = `${environment.baseUrl}/api/order/history/${orderId}`;
    return this.http.get<IBaseResponse<OrderWithHistory>>(url, {
      headers: this.headers,
    });
  }

  public fetchOrderDetails(orderId: string) {
    const url = `${environment.baseUrl}/api/order/details/${orderId}`;
    return this.http.get<IBaseResponse<OrderInQueue> | null>(url, {
      headers: this.headers,
    }).pipe(
      catchError(() => {
        return of(null);
      })
    );
  }

  public fetchEvents(organizationId: string, limit: number): Observable<IBaseResponse<EventModel<any>[]>> {
    const url = `${environment.baseUrl}/api/event/list/${organizationId}/limit/${limit}`;
    return this.http.get<IBaseResponse<EventModel<any>[]>>(url, {
      headers: this.headers,
    });
  }

  public confirmEvent(eventId: string): Observable<IBaseResponse<boolean>> {
    const url = `${environment.baseUrl}/api/event/confirm/${eventId}`;
    return this.http.get<IBaseResponse<boolean>>(url, {
      headers: this.headers,
    });
  }

  /**
   *
   * @param orderId
   * @param state
   * @deprecated
   */
  public manualChangeOrderStatus(orderId: string, state: number): Observable<IRpcResponse<OrderInQueue>> {
    const url = `${environment.baseUrl}/api/rpc`;
    const params = {
      orderId,
      status: state,
    };
    return this.rpcCall<OrderInQueue>(url, 'ManualChangeOrderStatus', params);
  }

  public manualChangeReflectedOrderStatus(order: ReflectedOrder, status: MasterOrderState): Observable<IRpcResponse<MasterOrderResponse>> {
    const url = `${environment.baseUrl}/api/rpc`;
    const params = {
      orderId: order.id,
      status: status
    };
    return this.rpcCall<MasterOrderResponse>(url, 'ManualChangeReflectedOrderStatus', params);
  }

  public getJSON(path: string): Observable<any> {
    return this.http.get(path);
  }

  public getPipes(organizationIds: Array<string>): Observable<IRpcResponse<PipeConfiguration[]>> {
    const url = `${environment.baseUrl}/api/rpc/pipe`;
    const params = {
      organizationIds
    };
    return this.rpcCall<PipeConfiguration[]>(url, 'Get', params);
  }

  public createPipe(pipeCreateRequest: any): Observable<IRpcResponse<PipeConfiguration>> {
    const url = `${environment.baseUrl}/api/rpc/pipe`;
    const params = {
      pipeCreateRequest
    };
    return this.rpcCall<PipeConfiguration>(url, 'Create', params);
  }

  public updatePipe(pipeUpdateRequest: any): Observable<IRpcResponse<PipeConfiguration>> {
    const url = `${environment.baseUrl}/api/rpc/pipe`;
    const params = {
      pipeUpdateRequest
    };
    return this.rpcCall<PipeConfiguration>(url, 'Update', params);
  }

  public switchPipe(pipeSwitchRequest: PipeSwitchRequest): Observable<IRpcResponse<PipeConfiguration>> {
    const url = `${environment.baseUrl}/api/rpc/pipe`;
    return this.rpcCall<PipeConfiguration>(url, 'Switch', pipeSwitchRequest);
  }

  public deletePipe(pipeId: string): Observable<IRpcResponse<PipeConfiguration>> {
    const url = `${environment.baseUrl}/api/rpc/pipe`;
    const params = {
      id: pipeId
    };
    return this.rpcCall<PipeConfiguration>(url, 'Delete', params);
  }

  public restorePipe(pipeId: string): Observable<IRpcResponse<PipeConfiguration>> {
    const url = `${environment.baseUrl}/api/rpc/pipe`;
    const params = {
      id: pipeId
    };
    return this.rpcCall<PipeConfiguration>(url, 'RestorePipe', params);
  }

  public movePipe(pipeId: string, moveToOrganizationId: string): Observable<IRpcResponse<PipeConfiguration>> {
    const url = `${environment.baseUrl}/api/rpc/pipe`;
    const params = {
      pipeId,
      moveToOrganizationId
    };
    return this.rpcCall<PipeConfiguration>(url, 'MovePipe', params);
  }

  public getConfigurations(organizationIds: any): Observable<IRpcResponse<Configuration[]>> {
    const url = `${environment.baseUrl}/api/rpc/configuration`;
    const params = {
      organizationIds
    };
    return this.rpcCall<Configuration[]>(url, 'Get', params);
  }

  public getConfigurationSchema(externalSystemType: ExternalSystemId): Promise<IRpcResponse<string>> {
    const url = `${environment.baseUrl}/api/rpc/configuration`;
    const params = {
      externalSystemType
    };
    return firstValueFrom(this.rpcCall<string>(url, 'GetSchema', params));
  }

  public updateConfiguration(configurationUpdateRequest: any): Observable<IRpcResponse<Configuration>> {
    const url = `${environment.baseUrl}/api/rpc/configuration`;
    const params = {
      configurationUpdateRequest
    };
    return this.rpcCall<Configuration>(url, 'Update', params);
  }

  public updateWebhookConfiguration(organizationGuid: string): Observable<IRpcResponse<Configuration>> {
    const url = `${environment.baseUrl}/api/rpc/configuration`;
    const params = {
      organizationGuid
    };
    return this.rpcCall<Configuration>(url, 'UpdateWebHookIiko', params);
  }

  public switchStatusConfiguration(cofigurationId: string, switchOnOff: boolean, reason: string)
    : Observable<IRpcResponse<Configuration>> {
    const url = `${environment.baseUrl}/api/rpc/configuration`;
    const params = {
      id: cofigurationId,
      switchOnOff,
      reason,
    };
    return this.rpcCall<Configuration>(url, 'SwitchConfigurationStatus', params);
  }

  public createConfiguration(configurationCreateRequest: ConfigurationCreateRequest): Observable<IRpcResponse<Configuration>> {
    const url = `${environment.baseUrl}/api/rpc/configuration`;
    const params = {
      configurationCreateRequest
    };
    return this.rpcCall<Configuration>(url, 'Create', params);
  }

  public deleteConfiguration(cofigurationId: string): Observable<IRpcResponse<Configuration>> {
    const url = `${environment.baseUrl}/api/rpc/configuration`;
    const params = {
      id: cofigurationId
    };
    return this.rpcCall<Configuration>(url, 'Delete', params);
  }

  public restoreConfiguration(cofigurationId: string): Observable<IRpcResponse<Configuration>> {
    const url = `${environment.baseUrl}/api/rpc/configuration`;
    const params = {
      id: cofigurationId
    };
    return this.rpcCall<Configuration>(url, 'RestoreConfiguration', params);
  }

  public moveConfiguration(configurationId: string, moveToOrganizationId: string): Observable<IRpcResponse<Configuration>> {
    const url = `${environment.baseUrl}/api/rpc/configuration`;
    const params = {
      configurationId,
      moveToOrganizationId
    };
    return this.rpcCall<Configuration>(url, 'MoveConfiguration', params);
  }

  public getDeliveryCalculates(orderId: string, pipeId: string): Observable<IRpcResponse<ICalculateDeliveryModel[]>> {
    return this.rpcCall<ICalculateDeliveryModel[]>(`${environment.baseUrl}/api/rpc/courier`, 'PreCalculateOrder', {
      orderId,
      pipeId
    });
  }

  public sendCourierOrder(
    hubOrderId: string,
    sourceOrderId: string,
    courierExternalSystemType: number,
    courierType: TravelMethod): Observable<IRpcResponse<any>> {
    const url = `${environment.baseUrl}/api/rpc/courier`;
    const params = {
      hubOrderId,
      sourceOrderId,
      courierExternalSystemType,
      courierType
    };
    return this.rpcCall(url, 'SendOrder', params);
  }

  public uploadMenu(pipeId: string): Observable<IRpcResponse<any>> {
    const url = `${environment.baseUrl}/api/rpc/menu`;
    const params = {
      pipeId
    };
    return this.rpcCall(url, 'Create', params);
  }

  public getMenuUrlById(menuId: string, isSource: boolean): Observable<IBaseResponse<string>> {
    const url = `${environment.baseUrl}/api/menu/download/${menuId}/${isSource}`;
    return this.http.get<IBaseResponse<string>>(url, {
      headers: this.headers,
    });
  }

  public getStopListUrlById(stopListId: string, isSource: boolean): Observable<IBaseResponse<string>> {
    const url = `${environment.baseUrl}/api/menu/stopList/download/${stopListId}/${isSource}`;
    return this.http.get<IBaseResponse<string>>(url, {
      headers: this.headers,
    });
  }

  public getBucketById(objectId: string, name: string): Observable<IBaseResponse<string>> {
    const url = `${environment.baseUrl}/api/menu/download-bucket/${objectId}/${name}`;
    return this.http.get<IBaseResponse<string>>(url, {
      headers: this.headers,
    });
  }

  public getMenuStorageList(pipeId: string): Promise<IRpcResponse<MenuStorage[]>> {
    const url = `${environment.baseUrl}/api/rpc/menu`;
    const params = {
      pipeId,
    };
    return firstValueFrom(this.rpcCall(url, 'GetMenuStorageList', params));
  }

  public confirmPublishingMenu(menuStorageId: string) : Promise<any> {
    const url = `${environment.baseUrl}/api/rpc/menu`;
    const params = {
      menuStorageId,
    };
    return firstValueFrom(this.rpcCall(url, 'ConfirmPublishing', params));
  }

  public cancelPublishingMenu(menuStorageId: string) : Promise<any> {
    const url = `${environment.baseUrl}/api/rpc/menu`;
    const params = {
      menuStorageId,
    };
    return firstValueFrom(this.rpcCall(url, 'CancelPublishing', params));
  }

  public getLatestMenuStoragesByPipeIds(pipeIds: string[]): Promise<IRpcResponse<MenuStorage[]>> {
    const url = `${environment.baseUrl}/api/rpc/menu`;
    const params = {
      pipeIds,
    };
    return firstValueFrom(this.rpcCall(url, 'GetLatestMenuStoragesByPipeIds', params));
  }

  public getStopListStorageList(pipeId: string): Observable<IRpcResponse<StopListStorage[]>> {
    const url = `${environment.baseUrl}/api/rpc/stopList`;
    const params = {
      pipeId,
    };
    return this.rpcCall(url, 'GetList', params);
  }

  public getOrganizationsList(): Observable<IRpcResponse<Organization[]>> {
    const url = `${environment.baseUrl}/api/rpc`;
    return this.rpcCall<Organization[]>(url, 'getOrganizationsList');
  }

  public createOrganization(org: CreateOrganization): Observable<IRpcResponse<CreateOrganization>> {
    const url = `${environment.baseUrl}/api/rpc`;
    return this.rpcCall<CreateOrganization>(url, 'createOrganization',
      {
        organizationRequestDto: org
      }
    );
  }

  public updateOrganization(org: UpdateOrganization): Observable<IRpcResponse<CreateOrganization>> {
    const url = `${environment.baseUrl}/api/rpc`;
    return this.rpcCall<CreateOrganization>(url, 'updateOrganization',
      {
        updatingData: org
      }
    );
  }

  public getUrlFromBucket(bucketKey: string, bucketName: BucketName): Observable<IBaseResponse<string>> {
    const url = `${environment.baseUrl}/api/order/history/bucket/${bucketName}`;
    const bucketRequest = {key: bucketKey};
    return this.http.post<IBaseResponse<string>>(url, JSON.stringify(bucketRequest), {
      headers: this.headers,
    });
  }

  public getOrganizationUsers(params: { organizationId: string }): Observable<IRpcResponse<OrganizationUser>> {
    const url = `${environment.baseUrl}/api/rpc`;
    return this.rpcCall<OrganizationUser>(url, 'getUsersByOrganizationId', params);
  }

  public getUsers(): Observable<IRpcResponse<UserWithAccess[]>> {
    const url = `${environment.baseUrl}/api/rpc`;
    return this.rpcCall(url, 'userList', {});
  }

  public addUserAccessToOrganization(params: AddUserAccessForOrganizationRequest): Observable<IRpcResponse<IBaseResponse<string>>> {
    const url = `${environment.baseUrl}/api/rpc`;
    return this.rpcCall<IBaseResponse<string>>(url, 'userAddAccessOrganization', {grantPermissionRequestDto: params});
  }

  public editUser(params: EditUserRequest): Observable<IRpcResponse<IBaseResponse<LoginResponse>>> {
    const url = `${environment.baseUrl}/api/rpc`;
    return this.rpcCall<IBaseResponse<LoginResponse>>(url, 'userUpdate', {userUpdateDto: params});
  }

  public userOwnUpdate(params: EditUserRequest): Observable<IRpcResponse<IBaseResponse<LoginResponse>>> {
    const url = `${environment.baseUrl}/api/rpc`;
    return this.rpcCall<IBaseResponse<LoginResponse>>(url, 'userOwnUpdate', {userUpdateDto: params});
  }

  public createUser(params: CreateUserRequest): Observable<IRpcResponse<LoginResponse>> {
    const url = `${environment.baseUrl}/api/rpc`;
    return this.rpcCall<LoginResponse>(url, 'userCreate', {registrationRequestDto: params});
  }

  public createUpdateSchedule(params: CreateUpdateScheduleRequest): Observable<IRpcResponse<UpdateSchedule>> {
    const url = `${environment.baseUrl}/api/rpc`;
    return this.rpcCall<UpdateSchedule>(url, 'createUpdateSchedule', {request: params});
  }

  public editUpdateSchedule(params: EditUpdateScheduleRequest): Observable<IRpcResponse<UpdateSchedule>> {
    const url = `${environment.baseUrl}/api/rpc`;
    return this.rpcCall<UpdateSchedule>(url, 'updateUpdateSchedule', {request: params});
  }

  public deleteUserAccessToOrganization(request: DeleteUserAccessToOrganizationRequest): Observable<IRpcResponse<IBaseResponse<string>>> {
    const url = `${environment.baseUrl}/api/rpc`;
    return this.rpcCall<IBaseResponse<string>>(url, 'deleteUserAccessToOrganization', {request});
  }

  public changePassword(request: UserChangePasswordRequest): Observable<IRpcResponse<IBaseResponse<LoginResponse>>> {
    const url = `${environment.baseUrl}/api/rpc`;
    return this.rpcCall<IBaseResponse<LoginResponse>>(url, 'userChangePassword', {changePassword: request});
  }

  public getNotificationConfigurationsForOrganization(organizationId: string): Observable<IRpcResponse<NotificationConfiguration[]>> {
    const url = `${environment.baseUrl}/api/rpc/notification-configuration`;
    return this.rpcCall<NotificationConfiguration[]>(url, 'getNotificationConfigurationsForOrganization', {organizationId});
  }

  public getNotificationSchema(notificationType: number): Observable<IRpcResponse<string>> {
    const url = `${environment.baseUrl}/api/rpc/notification-configuration`;
    return this.rpcCall<string>(url, 'getSchema', {notificationType});
  }

  public createNotificationConfiguration(configurationNotificationCreateRequest: ConfigurationNotificationCreateRequest)
    : Observable<IRpcResponse<Configuration>> {
    const url = `${environment.baseUrl}/api/rpc/notification-configuration`;
    const params = {
      request: configurationNotificationCreateRequest
    };
    return this.rpcCall<Configuration>(url, 'Create', params);
  }

  public updateNotificationConfiguration(configurationNotificationUpdateRequest: ConfigurationNotificationCreateRequest)
    : Observable<IRpcResponse<Configuration>> {
    const url = `${environment.baseUrl}/api/rpc/notification-configuration`;
    const params = {
      request: configurationNotificationUpdateRequest
    };
    return this.rpcCall<Configuration>(url, 'Update', params);
  }

  public getExternalSystemsInfo(): Observable<IRpcResponse<IExternalSystem[]>> {
    const url = `${environment.baseUrl}/api/rpc/system`;
    return this.rpcCall<IExternalSystem[]>(url, 'GetExternalSystemsInfo');
  }

  public getRevision(configurationId: string): Promise<RevisionMenuResponse> {
    const url = `${environment.baseUrl}/api/menu/revision/${configurationId}`;
    return firstValueFrom(this.http.get<RevisionMenuResponse>(url, {
      headers: this.headers,
    }));
  }

  public getUserActionsHistory(filter: UserActionFilter)
    : Promise<IBaseResponse<PageResult<UserAction>>> {
    const url = `${environment.baseUrl}/api/user-actions/list`;
    return firstValueFrom(this.http.post<IBaseResponse<PageResult<UserAction>>>(
      url,
      filter,
      {
        headers: this.headers
      }));
  }

  public setTestModeAsync(configurationIds: string[], isTestMode: boolean): Promise<IRpcResponse<Configuration>> {
    const url = `${environment.baseUrl}/api/rpc/configuration`;
    const params = {
      configurationIds,
      isTestMode,
    };
    return firstValueFrom(this.rpcCall<Configuration>(url, 'SetTestMode', params));
  }
}
