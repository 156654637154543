<div class="menu-history-modal">
  <h4>{{ 'MENU_HISTORY.HISTORY' | translate }}</h4>

  <table
    mat-table
    [dataSource]="dataSource"
    multiTemplateDataRows>

    <ng-container
        *ngFor="let column of columnsToDisplay"
        matColumnDef="{{ column }}">
        <th mat-header-cell *matHeaderCellDef>{{ getColumnHeader(column) }}</th>
        <td  class="cell" mat-cell *matCellDef="let element">
            <div [ngSwitch]="column">

              <ng-template ngSwitchCase="revision">
                <b>{{ element.revision }}</b>
              </ng-template>

              <ng-template ngSwitchCase="uploadedAt">
                {{element?.uploadedAt | utcToLocal: this.dateFormat}}
              </ng-template>

              <ng-template ngSwitchCase="published">
                {{element?.published | utcToLocal: this.dateFormat}}
                <div style="padding-top: 10px">
                  <button
                    mat-raised-button
                    matTooltip="{{ 'MENU_HISTORY.MENU_NO_PUBLISHED_TOOLTIP' | translate }}"
                    (click)="confirmPublish(element.id)"
                    *ngIf="!element?.published"
                  >
                    <mat-icon style="color: darkred">cancel</mat-icon>
                    <span style="font-size: 90%">{{ 'MENU_HISTORY.MENU_NO_PUBLISHED' | translate }}</span>
                  </button>

                  <button
                    mat-raised-button
                    matTooltip="{{ 'MENU_HISTORY.MENU_PUBLISHED_TOOLTIP' | translate }}"
                    (click)="cancelPublish(element.id)"
                    *ngIf="element?.published"
                  >
                    <mat-icon style="color: green">check_circle</mat-icon>
                    <span style="font-size: 90%">{{ 'MENU_HISTORY.MENU_PUBLISHED' | translate }}</span>
                  </button>
                </div>
              </ng-template>

              <ng-template ngSwitchCase="uploadMessage">
                {{element?.uploadMessage}}
              </ng-template>

              <ng-template ngSwitchCase="uploadResult">
                {{element?.uploadResult}}
              </ng-template>

              <ng-template ngSwitchCase="actions">
                  <div class="icons-cell">
                    <mat-icon class="link-icon" *ngIf="element?.sourceId !== null" (click)="downloadSourceMenu(element.id)"
                        matTooltip="{{ 'MENU.DOWNLOAD_SOURCE' | translate}}"
                        matTooltipPosition="above">cloud_download
                    </mat-icon>
                    <mat-icon class="link-icon" *ngIf="element?.targetId !== null" (click)="downloadTargetMenu(element.id)"
                        matTooltip="{{ 'MENU.DOWNLOAD_TARGET' | translate}}"
                        matTooltipPosition="above">cloud_download
                    </mat-icon>
                    <mat-icon class="link-icon" *ngIf="element?.derivativeId !== null" (click)="downloadBucket('derivative', element.derivativeId, element.uploadedAt)"
                        matTooltip="{{ 'MENU.DOWNLOAD_DERIVATIVE' | translate}}"
                        matTooltipPosition="above">cloud_download
                    </mat-icon>
                    <mat-icon class="link-icon" *ngIf="element?.guidCombinationsId !== null" (click)="downloadBucket('guidCombinations', element.guidCombinationsId, element.uploadedAt)"
                              matTooltip="{{ 'MENU.DOWNLOAD_GUID_COMBINATIONS' | translate}}"
                              matTooltipPosition="above">cloud_download
                    </mat-icon>
                  </div>
              </ng-template>

              <ng-template ngSwitchDefault>
                  {{ element[column] }}
              </ng-template>

            </div>

        </td>
    </ng-container>

    <tr
        mat-header-row
        *matHeaderRowDef="columnsToDisplay; sticky: true">
    </tr>
    <tr
        mat-row
        *matRowDef="let element; columns: columnsToDisplay">
    </tr>
</table>
</div>
